import React from 'react';
import './App.css';
import WideLogoLightBg from "./assets/img/logo-wide-light-bg.png";
import Screenshot1 from "./assets/img/screenshot1.png";
// import PawsImage from "./assets/img/paws.png";
import AppStoreBadge from "./assets/img/app_store_badge.svg";
import GooglePlayBadge from "./assets/img/google-play-badge.png";
import Theme from "./theme";
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
  isMobileSafari,
} from "react-device-detect";

class App extends React.Component{
  constructor(props){
    super(props);
    this.state={
    };


  }


  componentDidMount() {

  }


  render() {
    return(
      <div style={{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"stretch",
        // backgroundColor:Theme.color3,
        // backgroundImage:`url(${PawsImage})`,    
      }}
      >
        <img
        style={{
          alignSelf:"center"
        }}
        src={WideLogoLightBg} 
        alt={"Dog-Gone Logo"}
        width={300}
        height={69}
        />
        <h3 style={{
          fontFamily:Theme.secondaryFont,
          textAlign:"center",
        }}>
          Reunite Lost Pets Fast in Park City and Snyderville Basin.
        </h3>
        <img
        style={{
          alignSelf:"center"
        }}
        src={Screenshot1} 
        alt={"Dog-Gone Screenshot 1"}
        width={250}
        height={505}
        />

        <div style={{
          marginTop:40,
        }}></div>

        <div style={{            
            display:"flex",
            flexDirection:"row",
            justifyContent:"center",
        }}>
          <a 
          style={{
            alignSelf:"center",            
          }}
          target={"_blank"}
          rel="noopener noreferrer"
          href={"https://apps.apple.com/us/app/id1519637537"} 
          alt={"App Store Link"}>
            <img
            src={AppStoreBadge} 
            alt={"App Store Badge Link"}
            width={150}
            height={50}
            />
          </a>
          {isMobileSafari?
            null
            :
            <a 
            style={{
              alignSelf:"center",
              marginLeft:20,
            }}
            target={"_blank"}
            rel="noopener noreferrer"
            href={"https://play.google.com/store/apps/details?id=dev.aisoft.lost_dog_app"} 
            alt={"Google Play Store Link"}>
              <img
              src={GooglePlayBadge} 
              alt={"Google Play Store Badge Link"}
              width={170}
              height={52}
              />
            </a>
          }
        </div>
        <div style={{
          marginTop:40,
        }}></div>
        {/* <a name="donate"></a> */}
        <h1         
        style={{          
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          fontFamily:Theme.secondaryFont,
          textAlign:"center",
          color:Theme.color4,
        }}>
          <FavoriteIcon style={{ 
            color: Theme.color4,
            fontSize: 30,
            marginRight:10,
             }} />
          Donate
        </h1>
        <div style={{
          alignSelf:"center",
          width:"100%",
          maxWidth:800,
        }}>
          <iframe
          title="Dog-Gone Donate"
          src="https://givebutter.com/embed/c/Ch76LQ?wmode=opaque" 
          scrolling="no" 
          seamless="" 
          width="100%" 
          name="givebutter" 
          data-embed="true" 
          // style="max-width: 601px; overflow: hidden; height: 801px;"
          frameborder="0" 
          allowpaymentrequest="" 
          height="2000px" 
          >
          </iframe>
        </div>
      </div>




    );
  }

}


export default App;
