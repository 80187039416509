
class Theme{

  static primaryFont="'Michroma', sans-serif";
  static secondaryFont="'Montserrat', sans-serif";

  static color1="#2C34B1";
  static color2="#FFFFFF";
  static color3="#8476E4";
  static color4="#EF6984";
  static color5="#393939";

}

export default Theme;
